// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-datenschutz-js": () => import("/app/src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-generative-art-js": () => import("/app/src/pages/generative-art.js" /* webpackChunkName: "component---src-pages-generative-art-js" */),
  "component---src-pages-index-js": () => import("/app/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-20200807-js": () => import("/app/src/pages/jobs/20200807.js" /* webpackChunkName: "component---src-pages-jobs-20200807-js" */),
  "component---src-pages-slides-js": () => import("/app/src/pages/slides.js" /* webpackChunkName: "component---src-pages-slides-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/app/.cache/data.json")

